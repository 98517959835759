<!-- 顶栏消息图标 -->
<template>
<!-- transition="el-zoom-in-top"  在火狐不太流畅 -->
  <el-popover width="300" v-model="show" popper-class="ele-notice-pop" 
              class="ele-notice-group">
    <div class="ele-notice-group" slot="reference" @click="audioplay()">
      <el-badge :value="allNum" :hidden="!allNum"><i class="el-icon-bell"></i></el-badge>
    </div>
   
    <el-tabs v-if="show" v-model="active" class="user-info-tabs">
      <el-tab-pane :label="noticeLabel" name="notice">
        <div class="ele-notice-list ele-scrollbar-mini">
          <div v-for="(item,index) in noticeData" :key="index" class="ele-notice-item" @click="more(1)">
            <div class="ele-cell ele-notice-item-wrapper">
              <i :class="['el-icon-s-comment','ele-notice-item-icon']"></i>
              <div class="ele-cell-content">
                <div class="ele-elip">{{ item.title }}</div>
                <div class="ele-text-secondary ele-elip" id="content">{{ item.create_time*1000 | toDateString }}</div>
              </div>
            </div>
            <el-divider/>
          </div>
        </div>
        <div v-if="noticeData.length" class="ele-cell ele-notice-actions">
          <div @click="clear(1)" class="ele-cell-content">清空通知</div>
          <el-divider direction="vertical" class="line-color-light"/>
          <div @click="more(1)" class="ele-cell-content">查看更多</div>
        </div>
        <ele-empty v-if="!noticeData.length" text="你已查看所有通知"/>
      </el-tab-pane>
    </el-tabs>
  </el-popover>
</template>

<script>
export default {
  name: "EleNotice",
  data() {
    return {
      show: false,
      active: 'notice',
      notice: [
        {icon: 'el-icon-s-comment', title: '你收到了一封14份新周报', time: '2020-07-27 18:30:18'},
        {icon: 'el-icon-s-check', title: '许经理同意了你的请假申请', time: '2020-07-27 09:08:36'},
        {icon: 'el-icon-video-camera', title: '陈总邀请你参加视频会议', time: '2020-07-26 18:30:01'},
        {icon: 'el-icon-s-claim', title: '你推荐的刘诗雨已通过第三轮面试', time: '2020-07-25 16:38:46'},
        {icon: 'el-icon-message-solid', title: '你的6月加班奖金已发放', time: '2020-07-25 11:03:31'}
      ],
	noticeData:[]
    }
  },
	created(){
		this.getMessageList();
	},
  computed: {
    /* 通知标题 */
    noticeLabel() {
      if (this.noticeData.length) return `通知(${this.noticeData.length})`;
      else return '通知';
    },
    /* 所有消息数量 */
    allNum() {
      //this.lingdangnum=this.noticeData.length
      return this.$root.lingdang;
    }
  },
  mounted() {
    this.initload();
  },
  methods: {
    getMessageList(){
      this.$http.get('/message/messageList?status=1&is_platform=1').then(res => {
        //console.log('eeeeeeee33eeeeeeeeeee',res);
      	let data = JSON.parse(JSON.stringify(res.data))
      	this.noticeData = data.data
        this.$root.lingdang = this.noticeData.length
      })
      
    },
    /* 清空消息 */
    clear(type) {
      
      if (type === 1) {this.noticeData = [];this.clearAll();}
      else if (type === 2) {this.message = [];}
      else if (type === 3) {this.todo = [];}
      this.$root.lingdang=0
    },
    
    
    /* 查看更多 */
    more(type) {
      //console.log(type);
      // if (this.$route.path !== '/system/message/my_message') this.$router.push('/system/message/my_message');
      if (this.$route.path !== '/system/message/my_message') this.$router.push('/system/message/my_message');
      this.show = false;
    },
    audioplay(){
      this.getMessageList();
     
      
    },
    initload(){
      this.getMessageList();
      
    },
    /* 清空消息 */
    clearAll() {
      this.$http.get('/message/kjMsgAllDoRead').then(res => {
        //console.log(res)
      })
    },
  }
}
</script>

<style>
/* 消息通知pop */
.ele-notice-pop {
  margin: 0 !important;
  padding: 0 !important;
}

.ele-notice-group {
  vertical-align: top !important;
  display: inline-block;
}

.ele-notice-group .el-badge {
  line-height: normal;
}

/* tab */
.ele-notice-pop .el-tabs__nav-scroll {
  text-align: center;
}

.ele-notice-pop .el-tabs__nav {
  float: none;
  display: inline-block;
}

.ele-notice-pop .el-tabs__item {
  padding: 0 20px;
}

/* 列表 */
.ele-notice-list {
  padding-top: 8px;
  max-height: 360px;
  overflow: auto;
}

.ele-notice-item .ele-notice-item-wrapper {
  padding: 12px 15px;
  transition: background-color .2s;
  cursor: pointer;
}

.ele-notice-item .ele-notice-item-wrapper:hover {
  background-color: hsla(0, 0%, 60%, .05);
}

.ele-notice-item .ele-text-secondary {
  margin-top: 5px;
  font-size: 13px;
}

.ele-notice-item .ele-cell-desc {
  margin-top: 3px !important;
  font-size: 12px !important;
}

.ele-notice-item-icon {
  width: 32px;
  height: 32px;
  line-height: 32px !important;
  color: #FFF;
  font-size: 16px;
  background-color: #60B2FC;
  border-radius: 50%;
  text-align: center;
}

.ele-notice-item-icon.el-icon-s-check {
  background-color: #F5686F;
}

.ele-notice-item-icon.el-icon-video-camera {
  background-color: #7CD734;
}

.ele-notice-item-icon.el-icon-s-claim {
  background-color: #FAAD14;
}

.ele-notice-item-icon.el-icon-message-solid {
  background-color: #2BCACD;
}

/* 操作按钮 */
.ele-notice-actions > .ele-cell-content {
  line-height: 42px;
  text-align: center;
  cursor: pointer;
}

.ele-notice-actions > .ele-cell-content:hover {
  background-color: hsla(0, 0%, 60%, .05);
}

/* 空视图 */
.ele-notice-pop .ele-empty {
  padding: 100px 0;
}
.ele-elip{
	width:220px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>