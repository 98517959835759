<!-- 顶栏 -->
<template>
  <div :class="['ele-admin-header',{'ele-bg-primary':theme.headStyle===2}]">
<!--    <div class="ele-admin-logo"><img src="@/assets/logo1.png" alt="logo"/><span style="font-size: 12px;">{{ $setting.name }}</span></div>-->
		<div style="display: flex;justify-content: space-between;width: 100%;">
			<!-- <div style="display: flex;"> -->
				<div class="ele-admin-logo" style="display: block;text-align: center; ">
          <!-- <img style="width: 100%;height: 100%;" src="./63.png" alt="logo" v-if="theme.collapse"/> -->
          <img  :style="theme.collapse ? 'width:60px;height:60px' : 'width:38%;margin-top: 10px;'" :src="theme.collapse ? mayiImg1 : mayiImg2" alt="logo" />
					<p style="    margin-top: 10px;"><span style="font-size: 12px;">{{ $setting.name }}</span></p>
				</div>
				<!-- 左侧功能区 -->
				<el-menu v-if="showLeft" mode="horizontal" :class="['ele-header-left',{'ele-menu-dark':theme.headStyle!==0}]">
					<el-menu-item index="fold" @click="collapse"><i :class="theme.collapse?'el-icon-_unfold':'el-icon-_fold'"></i>
					</el-menu-item>
					<el-menu-item index="refresh" @click="refresh"><i class="el-icon-refresh-right"></i></el-menu-item>
				</el-menu>
				<ele-breadcrumb v-if="theme.layoutStyle===0"/><!-- 面包屑导航 -->
				<ele-header-nav v-if="theme.layoutStyle!==0"/><!-- 顶部菜单区 -->
			<!-- </div> -->
			<!-- 右侧功能区 -->
			<ele-header-right/>
		</div>
    
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import EleBreadcrumb from './Breadcrumb'
import EleHeaderNav from './HeaderNav'
import EleHeaderRight from '../fragment/HeaderRight'
import NProgress from "nprogress"

export default {
  name: "EleHeader",
  components: {EleBreadcrumb, EleHeaderNav, EleHeaderRight},
  data() {
    return {
      mayiImg1:require('./63.png'),
      mayiImg2:require('@/assets/logo1.png')

    }
  },
  computed: {
    showLeft() {  // 是否显示左侧部分menu
      return this.theme.layoutStyle !== 1 || this.theme.screenWidth < 768;  // 小屏幕强制显示
    },
    ...mapGetters(['theme'])
  },
  methods: {
    /* 折叠 */
    collapse() {
      this.$store.dispatch('theme/toggle', 'collapse');
    },
    /* 刷新 */
    refresh() {
      NProgress.start();
      if (this.theme.reload) return;
      this.$store.dispatch('theme/toggle', 'reload');
      this.$nextTick(() => {
        this.$store.dispatch('theme/toggle', 'reload');
        setTimeout(() => {
          NProgress.done();
        }, 150);
      });
    }
  }
}
</script>
