<!-- 顶栏右侧部分 -->
<template>
	<el-menu mode="horizontal" :class="['ele-header-right',{'ele-menu-dark':$store.state.theme.headStyle!==0}]">
		<span>
			总资产：<span style="color:rgb(51,207,153);margin-right: 20px;">{{user.balance}} 元</span>
			可提现：<span style="color:rgb(255,78,0);margin-right: 20px;">{{user.balance}} 元</span>
			总消费：<span style="color:rgb(255,78,0);margin-right: 24px;">{{user.all_amount}} 元</span>
		</span>
		<a @click="dialogVisiblePlans = true" style="color:#33cc99 !important;margin-right: 24px;cursor: pointer;">立即充值</a>
		<!--立即充值-->
		
		<el-menu-item index="full" @click="fullScreen">
			<i :class="isFullScreen?'el-icon-_screen-restore':'el-icon-_screen-full'"></i>
		</el-menu-item>
		<el-menu-item index="notice">
			<ele-notice /><!-- 消息通知组件 -->
		</el-menu-item>
		<el-submenu index="user" popper-class="ele-menu-pop">
			<template slot="title">
				<el-avatar :src="user.avatar" />
				<span>{{ user.realname?user.realname:user.username?user.username:'' }}</span>
			</template>
			<el-menu-item index="info" @click="goUserInfo" v-if="user.has_admininfo_auth"><i class="el-icon-user"></i><span>个人中心</span></el-menu-item>
			     <!-- <el-menu-item index="password" @click="showPassword"><i class="el-icon-key"></i><span>修改密码</span></el-menu-item> -->
			<el-divider />
			<el-menu-item index="logout" @click="logout"><i class="el-icon-switch-button"></i><span>退出登录</span>
			</el-menu-item>
		</el-submenu>
		<!-- <el-menu-item index="more" v-if="$setting.showSetting" @click="showSetting">
			<i class="el-icon-_more"></i>
		</el-menu-item> -->
		<el-dialog v-dialogDrag title="立即充值" :modal-append-to-body='false'  :visible.sync="dialogVisiblePlans" custom-class="ele-dialog-form" :lock-scroll="false"
			@closed="editForm={}">
			<el-card shadow="never">
				<el-form :model="editForm" label-width="170px" class="plansBox">
					<el-form-item label="充值金额(元)：" prop="money">
						<el-input type="number" min='1' v-model="editForm.money" placeholder="请输入充值金额" class="input216" clearable /> 元
					</el-form-item>
					<el-form-item label="支付方式：" prop="pay">
						<el-button type="primary" @click="handleWxpay">微信支付</el-button>
						<!--<el-button type="primary" @click="handleAlipay">支付宝支付</el-button>-->
					</el-form-item>
					<div style="color: orangered;margin-left: 90px;width: 500px;">
						<div>*充值金额将直接到达可蚁点平台的微信商户平台，但微信商户会收取0.6%的手续费，</div>
						<div>所以最终充值实际到账金额=充值金额*0.6%</div></div>
					
				</el-form>
			</el-card>
		</el-dialog>
	</el-menu>
	
</template>

<script>
	import EleNotice from './Notice'
	import urlencode from "urlencode";
  import setting from '../../../config/setting';
	export default {
		name: "EleHeaderRight",
		components: {
			EleNotice
		},
		data() {
			return {
				isFullScreen: false, // 是否全屏
				form: [],
				editForm: {}, // 表单数据
				dialogVisiblePlans:false,
        admin_id: 0
			}
		},
		computed: {
			user() { // 当前登录用户信息
				return this.$store.state.user.user || {};
			}
		},
		mounted() {
			this.getUserInfo();
		},
		methods: {
			/* 获取当前用户信息 */
			getUserInfo() {
				if (this.$setting.userUrl) {
					this.$http.get(this.$setting.userUrl).then(res => {
						if (res.data.code === 0) {
							if (this.$setting.parseUser) {
								res.data = this.$setting.parseUser(res.data);
							}
							this.form = JSON.parse(JSON.stringify(res.data.data))
							let user = res.data.data;
							this.admin_id = user.id;
							this.$store.dispatch('user/setUser', user);
							this.$store.dispatch('user/setRoles', user ? user.roles : null);
							this.$store.dispatch('user/setAuthorities', user ? user.authorities : null);
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						this.$message.error(e.message);
					});
				}
			},
			/* 退出登录 */
			logout() {
				this.$confirm('确定要退出登录吗?', '提示', {
					type: 'warning'
				}).then(() => {
					this.$http.get('/login/logout').then((res) => {
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: '退出成功'
							});
							// 清除缓存的token
							this.$store.dispatch('user/setToken').then(() => {
								// this.$router.push({path: "/login"});
								window.location.replace('/');
							});
						} else {
							this.$message.error(res.data.msg);
						}
					});
				}).catch(() => 0);
			},
			/* 跳转到个人中心 */
			goUserInfo() {
				if (this.$route.fullPath !== '/admin/info') this.$router.push('/admin/info');
			},
			/* 打开修改密码弹窗 */
			showPassword() {
				this.$store.dispatch('theme/toggle', 'showPassword');
			},
			/* 打开主题设置 */
			showSetting() {
				this.$store.dispatch('theme/toggle', 'showSetting');
			},
			/* 全屏 */
			fullScreen() {
				this.isFullScreen = !this.$util.fullScreen();
				if (!this.$setting.watchResize) this.$store.dispatch('theme/set', {
					key: 'screenWidth',
					value: document.documentElement.clientWidth || document.body.clientWidth
				});
			},
			rechrageNow() {

			},
			handleWxpay() {
				if (parseFloat(this.editForm.money) >= 1) {
					let url = "?type=1&price=" + this.editForm.money + "&id=" + this.admin_id;
					url = urlencode(url);
					window.open(setting.payUrl + 'index.php/SystemPay/wx_pay?'+url)
					this.dialogVisiblePlans = false
					this.table.reload();
					//  this.$router.push({ path: "/system/adminiaelist" });
				}else{
					this.$message({type: 'error', message: '充值金额需要大于1元'});
				}
				// if(this.editForm.money<0 || this.editForm.money<1000){
				//    this.$message({type: 'error', message: '充值金额必须大于1000'});
				//
				// }
				if (!this.editForm.money) {
					this.$message({
						type: 'error',
						message: '请输入充值金额'
					});
				}

			},
			handleAlipay() {
				if (parseFloat(this.editForm.money) > 1) {
					let url = "?type=2&price=" + this.editForm.money + "&id=" + this.admin_id;
					url = urlencode(url);
					window.open(setting.payUrl + 'index.php/SystemPay/ali_pay?'+url)
					this.dialogVisiblePlans = false
					//  this.$router.push({ path: "/system/adminiaelist" });
				}else{
					this.$message({type: 'error', message: '充值金额需要大于1元'});
				}
				// if(this.editForm.money<0 || this.editForm.money<1000){
				//    this.$message({type: 'error', message: '充值金额必须大于1000'});
				//
				// }
				if (!this.editForm.money) {
					this.$message({
						type: 'error',
						message: '请输入充值金额'
					});
				}
			},
		}
	}
</script>
<style >
input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"]{
    -moz-appearance: textfield;
  }
</style>
