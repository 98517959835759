<template>
  <div>
    <div id="app">
      
      

      <el-container>
        <el-main style="padding:0">
          <router-view v-on:public_footer="public_footer" />
        </el-main>
        <el-footer v-show="!$route.meta.showFooter">
          <div
            style="padding:10px;text-align:center;background:#fff;margin-left:55px;flex:0;left:0;right:0;bottom:0;z-index:1001;"
            id="footer">
            <div>Copyright © 2017~2023 livingtrip.uyl.cn. All Rights Reserved. 依蓝云（北京）科技有限公司 版权所有 ISP证：鲁B1-20190022
            </div>
            <div>系统版本：v3.1.12</div>
          </div>
        </el-footer>
      </el-container>
    </div>
    
   


    <!-- tinymce富文本编辑 增加视频上传加载提示 -->
    <div id="tinymceMask" style="display: none;">
      <div class="con">正在上传中...</div>
    </div>
    <div id="tinymceMaskError" style="display: none;">
      <div class="con tinymceMaskError"></div>
    </div>
  </div>
</template>
<script>
  import store from '@/store'
  import {
    Switch
  } from 'element-ui';
  export default {
    name: "app",
    data() {
      return {
        footer_show: true,
        // dialogVisible: false,
      }
    },
    mounted() {
     
      var height = window.screen.height
      var footerTop = document.getElementById('footer').offsetTop
      // var footerHeight=document.getElementById('footer').height

      if (height - footerTop - 60 > 500) {
        document.getElementById('footer').style.position = "fixed"
      } else {
        document.getElementById('footer').style.position = "fixed"
      }
    },
    methods: {
     
      handleClose(done) {
              this.$confirm('确认关闭？')
                .then(_ => {
                  done();
                })
                .catch(_ => {});
            },
      public_footer: function(bool) {
        if (bool) {
          this.footer_show = true;
        } else {
          this.footer_show = false
        }
      },
      
    }

  }
</script>
<style scoped>
  #tinymceMask {
    background-color: rgba(0, 0, 0, 0.25);
    left: 0;
    position: absolute;
    top: 0;
    z-index: 19892220;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  #tinymceMask .con {
    width: 200px;
    height: 100px;
    border-radius: 20px;
    text-align: center;
    line-height: 100px;
    font-size: 20px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  #tinymceMaskError {
    background-color: rgba(0, 0, 0, 0.25);
    left: 0;
    position: absolute;
    top: 0;
    z-index: 19892220;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  #tinymceMaskError .con {
    width: 200px;
    height: 100px;
    border-radius: 20px;
    text-align: center;
    line-height: 100px;
    font-size: 20px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #a94442;
  }
</style>
