import config from './WebIMConfig';
import websdk from "easemob-websdk";

import _ from 'lodash';
// import emedia from 'easemob-emedia';
// import webrtc from "easemob-webrtc";
import webrtc from './EMedia_x1v1_3.4.1';

// 声网音视频sdk
// import AgoraRTC from 'AgoraRTCSdkNg';

// import { Message } from 'ant-design-vue';
function ack(message){
	var bodyId = message.id; // 需要发送已读回执的消息id
	var msg = new WebIM.message('read', WebIM.conn.getUniqueId());
	msg.set({
		id: bodyId,
		to: message.from
	});
	WebIM.conn.send(msg.body);
}
const rtc = {
	// 用来放置本地客户端。
	client: null,
	// 用来放置本地音视频频轨道对象。
	localAudioTrack: null,
	localVideoTrack: null,
};

// 初始化IM SDK
let WebIM = {};
WebIM = window.WebIM = websdk;
WebIM.config = config;
WebIM.conn = new WebIM.connection({
	isReport: false,
	appKey: WebIM.config.appkey,
	isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
	https: WebIM.config.https,
	isAutoLogin: true,
	heartBeatWait: WebIM.config.heartBeatWait,
	autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
	autoReconnectInterval: WebIM.config.autoReconnectInterval,
	isStropheLog: WebIM.config.isStropheLog,
	delivery: WebIM.config.delivery,

	// 公有云 isHttpDNS 默认配置为true
	isHttpDNS: WebIM.config.isHttpDNS,

	// 私有云设置，详细文档：http://docs-im.easemob.com/im/web/other/privatedeploy
	// isHttpDNS: false,
	// url: 'https://im-api-v2.easecdn.com/ws', // 设置为私有云的websocket server url
	// apiUrl: 'https://a1.easecdn.com', // 设置为私有云的rest server url

	// url: 'http://msync-api-a1-test.easemob.com:8081/ws', // 设置为私有云的websocket server url
	// url: window.location.protocol + '//msync-api-a1-test.easemob.com/ws', // 设置为私有云的websocket server url

	// url: 'https://im-api-v2-hsb.easemob.com/ws', // 设置为私有云的websocket server url
	// url: 'http://52.80.99.104:8081/ws', // 设置为私有云的websocket server url
	// apiUrl: 'http://a1-test.easemob.com:8089', // 设置为私有云的rest server url
	// apiUrl: window.location.protocol + '//a1-test.easemob.com', // 设置为私有云的rest server url
});

// 通话状态
const CALLSTATUS = {
	idle: 0,
	inviting: 1,
	alerting: 2,
	confirmRing: 3, // caller
	receivedConfirmRing: 4, // callee
	answerCall: 5,
	receivedAnswerCall: 6,
	confirmCallee: 7
};

// 注册监听回调
// WebIM.conn.listen({
// 	onOpened: function(message){ // 连接成功回调
// 		// 登录或注册成功后 跳转到好友页面
		
// 	},
// 	onClosed: function(message){
// 	}, // 连接关闭回调
// 	onTextMessage: function(message){
// 		console.log('onTextMessage', message);
// 	}, // 收到文本消息
// 	onEmojiMessage: function(message){
// 		console.log('onEmojiMessage', message);	
// 	}, // 收到表情消息
// 	onPictureMessage: function(message){
		
// 	}, // 收到图片消息
// 	onCmdMessage: function(msg){
// 		console.log('onCmdMessage', msg);
// 	},
// 	// 收到命令消息
// 	onAudioMessage: function(message){
// 		console.log('onAudioMessage', message);
// 	}, // 收到音频消息
// 	onLocationMessage: function(message){
// 		console.log('onLocationMessage', message);
// 	}, // 收到位置消息
// 	onFileMessage: function(message){
// 	},
// });

WebIM.WebRTC = window.webrtc; // 本项目引入 UMD 文件有问题，暂时这样引入
WebIM.EMedia = window.emedia;
// WebIM.AgoraRTC = AgoraRTC;
WebIM.rtc = rtc;

export default WebIM;
