//除法函数，用来得到精确的除法结果
//说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
//调用：accDiv(arg1,arg2)
//返回值：arg1除以arg2的精确结果
function accDiv(arg1, arg2) {
	var t1 = 0, t2 = 0, r1, r2;
    if(arg1.toString().split(".")[1]!=undefined){
        t1 = arg1.toString().split(".")[1].length
    }
    if(arg2.toString().split(".")[1]!=undefined){
        t2 = arg2.toString().split(".")[1].length
    }
  r1 = Number(arg1.toString().replace(".", ""));
  r2 = Number(arg2.toString().replace(".", ""));
  if(r2==0){
    return Infinity;
  }else{
    return (r1 / r2) * Math.pow(10, t2 - t1);
	}
}
//乘法函数，用来得到精确的乘法结果
//说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
//调用：accMul(arg1,arg2)
//返回值：arg1乘以arg2的精确结果
function accMul(arg1, arg2) {
	var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    if(s1.split(".")[1]!=undefined){
        m += s1.split(".")[1].length 
    }
    if(s2.split(".")[1]!=undefined){
        m += s2.split(".")[1].length 
    }

	return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}
//加法函数，用来得到精确的加法结果
//说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
//调用：accAdd(arg1,arg2)
//返回值：arg1加上arg2的精确结果
function accAdd(arg1, arg2) {
	var r1, r2, m;
	try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
	try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
	m = Math.pow(10, Math.max(r1, r2));
	return (arg1 * m + arg2 * m) / m;
}
//减法函数
function accSub(num2,num1) {
	// num1 -num2
	var baseNum, baseNum1, baseNum2;
	var precision;// 精度
	try {
		baseNum1 = num1.toString().split(".")[1].length;
	} catch (e) {
		baseNum1 = 0;
	}
	try {
		baseNum2 = num2.toString().split(".")[1].length;
	} catch (e) {
		baseNum2 = 0;
	}
	baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
	precision = (baseNum1 >= baseNum2) ? baseNum1 : baseNum2;
	return ((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(precision);

}


//给Number类型增加一个add方法，调用起来更加方便。
Number.prototype.add = function (arg) {
	return accAdd(arg, this);
};
//给Number类增加一个sub方法，调用起来更加方便
Number.prototype.sub = function (arg) {
	return accSub(arg, this);
};
//给Number类型增加一个mul方法
Number.prototype.mul = function (arg) {
	return accMul(arg, this);
};
//给Number类型扩展一个div方法
Number.prototype.div = function (arg) {
	return accDiv(this, arg);
};

export default {  
  accDiv,
  accMul,
  accAdd,
  accSub,
}

