<!-- 页面布局 -->
<template>
	<div :class="layoutClass">
		<ele-header /><!-- 顶栏 -->
		<el-container class="ele-admin-main">
			<ele-sidebar v-if="showSidebar" /><!-- 侧边栏 -->
			<div class="ele-admin-body">
				<ele-tabs v-if="theme.showTabs" /><!-- 多页签 -->
				<keep-alive :include="$setting.keepAliveList">
					<router-view v-if="!theme.reload" /><!-- 路由出口 -->
				</keep-alive>
			</div>
		</el-container>
		<div class="ele-admin-shade" @click="collapse"></div><!-- 小屏幕下遮罩层 -->
		<ele-setting v-if="$setting.showSetting" /><!-- 主题设置抽屉 -->
		<ele-password /><!-- 修改密码弹窗 -->

		<!-- <el-dialog v-dialogDrag :title="editForm.title" :visible.sync="dialogVisible" width="1300px" @closed="closeOrder()"
			:destroy-on-close="true" :lock-scroll="false"> -->
			<el-dialog v-dialogDrag :title="editForm.title" :visible.sync="dialogVisible" width="1300px" @closed="closeOrder()"
				:destroy-on-close="true" :lock-scroll="false">
			<div>
				<div id="orderMap2" class="order-map2">

				</div>
				<div class="map-form2">
					<el-card shadow="never">
						<el-form :model="editForm" ref="editForm" label-width="120px" :rules="editRules">
							<el-form-item label="订单所属地：" prop="pid">
								<el-select v-model="editForm.pid" @change="handleChangeProv(editForm.pid)" class="city-select2"
									clearable placeholder="请选择省">
									<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
										:label="option.name"></el-option>
								</el-select>
								<el-select v-model="editForm.cid" @change="handleChangeCity(editForm.cid)" class="city-select2"
									clearable placeholder="请选择市">
									<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
										:label="option.name"></el-option>
								</el-select>
								<el-select v-model="editForm.aid" @change="$forceUpdate()" class="city-select2" clearable
									placeholder="请选择区/县">
									<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
										:label="option.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="乘客叫单号码：" prop="phone">
								<el-input v-model="editForm.phone" :value="phone" style="width: 200px;" placeholder="请输入手机号或座机号"
									clearable />
							</el-form-item>
							<el-form-item label="用户语音：">
								<audio controls="controls">
									<source :src="editForm.jiequ_audio" type="audio/ogg">
									<source :src="editForm.jiequ_audio" type="audio/mpeg">
								</audio>

							</el-form-item>
							<div style="display: flex;">
								<div class="place_point"></div>
								<el-form-item label="出发地：" style="margin-left: -50px;">
									<!-- <el-select style="width: 300px;" v-model="editForm.name2" filterable remote reserve-keyword
					          placeholder="请输入关键词" :remote-method="remoteMethod2" :loading="sloading2" @change="schange2">
					          <el-option v-for="item in sOptions2" :key="item.value" :label="item.label" :value="item.value">
					          </el-option>
					        </el-select> -->
									<el-autocomplete popper-class="my-autocomplete2" v-model="editForm.name2" :debounce="300"
										:fetch-suggestions="querySearchAsync2" :trigger-on-focus="false" placeholder="输入关键词"
										class="order-input2" @select="schange2">
										<template slot-scope="{ item }">
											<div class="name" v-html="searchTitleHtml(item.label)"></div>
											<div class="addr">
												{{item.district?item.district + item.address:item.pname +item.cityname + item.adname + item.address}}
											</div>
										</template>
									</el-autocomplete>
									<i v-show="!iconLoading" class="el-icon-refresh-right refresh-cars2"
										style="margin-left: 10px; font-size: 20px;" @click="refreshCars2"></i>
									<i v-show="iconLoading" class="el-icon-loading refresh-cars2"
										style="margin-left: 10px; font-size: 20px;"></i>
								</el-form-item>
							</div>

							<div style="display: flex;margin-top: 20px;">
								<div class="target_point"></div>
								<el-form-item label="目的地：" style="margin-left: -50px;">
									<!-- <el-select style="width: 300px;" v-model="editForm.name" filterable remote reserve-keyword
					          placeholder="请输入关键词" :remote-method="remoteMethod" :loading="sloading" @change="schange">
					          <el-option v-for="item in sOptions" :key="item.value" :label="item.label" :value="item.value">
					          </el-option>
					        </el-select> -->
									<el-autocomplete popper-class="my-autocomplete2" v-model="editForm.name" :debounce="300"
										:fetch-suggestions="querySearchAsync" :trigger-on-focus="false" placeholder="输入关键词"
										class="order-input2" @select="schange">
										<template slot-scope="{ item }">
											<div class="name" v-html="searchTitleHtml(item.label)"></div>
											<div class="addr">
												{{item.district?item.district + item.address:item.pname +item.cityname + item.adname + item.address}}
											</div>
										</template>
									</el-autocomplete>
								</el-form-item>
							</div>
						</el-form>
						<div style="margin-top: 20px; text-align: center;">
							<el-button type="primary" @click="orderSave">确认下单</el-button>
						</div>
					</el-card>
				</div>
				<div class="zd-order2" v-show="zdOrederShow" :style="{top:editForm.is_landline_phone == 1?'450px':'370px'}">
					<el-card shadow="never">
						<div class="zd-order-header2">
							<span class="header-tit2">车辆状态</span>
							<span class="header-close2" @click="zdClose">X</span>
						</div>
						<div class="zd-order-cont2">
							<div>
								<span>车牌号:</span>
								<span>{{carDet.car_number}}</span>
							</div>
							<div>
								<span>司机姓名:</span>
								<span>{{carDet.name}}</span>
							</div>
							<div>
								<span>司机账号:</span>
								<span>{{carDet.driver_phone}}</span>
							</div>
							<div>
								<span>目前状态:</span>
								<span>{{carDet.is_carry==0 && carDet.is_accept_user_specified_order == 0?'空车'
								:carDet.is_carry==0 && carDet.is_accept_user_specified_order != 0?'点我'
								:'载客'}}</span>
								<span v-if="carDet.is_accept_user_specified_order == 0">(不接收指派订单)</span>
								<span v-if="carDet.is_accept_user_specified_order != 0 && carDet.is_carry==0">
									{{"(接收指派订单,距离出发地约" + carDet.distance +"公里)"}}
								</span>
							</div>
						</div>
						<el-button class="zp-btn2"
							:type="carDet.is_carry==1 || carDet.is_accept_user_specified_order==0?'info':'primary'"
							:disabled="carDet.is_carry==1 || carDet.is_accept_user_specified_order==0?true:false"
							@click="saveZdOreder">指派下单</el-button>
					</el-card>
				</div>
			</div>

		</el-dialog>

		<audio src="https://kyd.oss.livingtrip.uyl.cn/2b986e92c6224afcebf96484b7debab2.mp4" ref="MusicPlay" hidden></audio>
	</div>
</template>

<script>
	import WebIM from '@/utils/WebIM.js';
	import config from "@/config/setting.js"
	import MiddleUtil from '@/config/middles.js';
	import {
		mapGetters
	} from 'vuex'
	import EleHeader from './Header'
	import EleSidebar from './Sidebar'
	import EleTabs from './Tabs'
	import EleSetting from '../fragment/Setting'
	import ElePassword from '../fragment/Password'
	import store from '@/store'
	export default {
		name: "EleLayout",
		data() {
			return {
				phone: '',
				iconLoading: false,
				editForm: {
					phone: '',
					name: '',
					name2: '',
					order_id: '',
					pname: '',
					pid: '',
					cname: '',
					cid: '',
					aname: '',
					aid: '',
					lng: null,
					lng2: null,
					lat: null,
					lat2: null,
					jiequ_audio: '',
					audio: '',
					title: '有新的模糊电召订单,请及时回访协助下单!',
					place_address_title: '',
					target_address_title: '',
				},
				city: '',
				sOptions: [],
				timer: null,
				sloading: false,
				map3: null,
				sOptions2: [],
				timer2: null,
				sloading2: false,
				map4: null,

				dialogVisible: false,
				startLng: 0,
				startLat: 0,
				isReloadData: true,
				pickerOptionsStart: {
					disabledDate: time => {
						let endDateVal = this.tableend_time;
						if (endDateVal) {
							return time.getTime() > new Date(endDateVal).getTime();
						}
					}
				},
				pickerOptionsEnd: {
					disabledDate: time => {
						let beginDateVal = this.tableCreate_time;
						if (beginDateVal) {
							return (
								time.getTime() <
								new Date(beginDateVal).getTime()
							);
						}
					}
				},
				isPlay: true,
				tableCreate_time: '', //搜索选中时间戳
				tableend_time: '',
				bgWidth: '',
				aa: true,
				ampDisplay: false,
				charList: [],
				table: {
					url: '/order/indexOrderLivery',
					where: {
						source: 2
					}
				}, // 表格配置
				checkForm: {},
				editRules: {
					pid: [{
						required: true,
						message: '请选择区域信息',
					}],
					cid: [{
						required: true,
						message: '请选择区域信息',
					}],
					aid: [{
						required: true,
						message: '请选择区域信息',
					}],
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, {
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						message: '请输入正确的手机号',
						trigger: 'blur',
					}],
				},
				dialogTableVisibleCheck: false,
				dialogTableVisibleChatRecord: false,
				provArr: [],
				time: [],
				cityArr: [],
				districtArr: [],
				audioArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				zoom: 12,
				center: [104.07, 30.67],
				ampSrc: '',
				driverInfo: {},
				orderInfo: {},
				audio: '',
				autoPlays: false,
				dialogFormVisibleAddReturnActivity: false,
				map: null,
				map2: null,
				changePro: false,
				changeCity: false,
				changeArea: false,
				orderMap2: null,
				marker: null,
				marker2: null,
				carDet: {},
				zdOrederShow: false,
				searchValue: "",
				adcodes: '',
				markerCars: [],
				adminCid: "",
				aid: '',
				infoWindowObj: '' ,//点击窗体按钮缓存的数据
				order_id_list:[]
			}
		},

		components: {
			EleHeader,
			EleSidebar,
			EleTabs,
			EleSetting,
			ElePassword
		},
		computed: {
			// dialogVisible() {
			//   return this.$store.state.theme.orderShow;
			// },
			/* 框架布局class */
			layoutClass() {
				return [
					'ele-admin-layout',
					{
						'ele-layout-collapse': this.theme.collapse
					}, // 折叠状态
					{
						'ele-layout-fixed': this.theme.fixedLayout
					}, // 固定顶栏和侧栏
					{
						'ele-side-dark': this.theme.sideStyle === 1
					}, // 暗色侧栏
					{
						'ele-side-color-icon': this.theme.colorfulIcon
					}, // 侧栏彩色图标
					{
						'ele-head-dark': this.theme.headStyle !== 0
					}, // 暗色顶栏
					{
						'ele-logo-auto-size': this.theme.logoAutoSize || this.theme.layoutStyle === 1
					}, // logo宽度自适应
					{
						'ele-tabs-show': this.theme.showTabs
					}, // 开启多页签
					['', 'ele-tab-dot', 'ele-tab-card'][this.theme.tabStyle], // 页签风格
					{
						'ele-admin-body-limit': !this.theme.bodyFull
					} // 主体内容定宽
				]
			},
			/* 是否显示侧边栏 */
			showSidebar() {
				return this.theme.layoutStyle !== 1 || this.theme.screenWidth < 768; // 小屏幕强制左侧菜单模式
			},
			...mapGetters(['theme']),
		},
		created() {
			window.setAddress2 = (e) => {
				if (e == '1') { //设为出发地
					this.$set(this.editForm, "name2", '')
					this.schange2(this.infoWindowObj)
				} else {
					this.$set(this.editForm, "name", '')
					this.schange(this.infoWindowObj)
				}
			}
			this.connectWebsocket();
			this.$http.get('/common/province_list').then(res => {
				var data = JSON.parse(res.data)
				this.provArr = data

			})

			this.getUserInfo();
		},
		mounted() {
			console.log('WebIM', WebIM)
			// this.$http.get('/common/province_list').then(res => {
			// 	var data = JSON.parse(res.data)
			// 	this.provArr = data
			// })
			this.getAdcodes()
			
			// this.getOrder()

			let _this = this;
			MiddleUtil.$on('getOrderInfo', (order_id,is_play_audio) => {
				// console.log('order_id!@!!', order_id)
				_this.getOrderInfo(order_id,is_play_audio);
			})

			if (this.$setting.watchResize) {
				window.addEventListener('resize', this.onWindowResize);
			}
			// this.$nextTick(() => {
			//   this.$refs.MusicPlay.src = this.$root.lingdangAudio;
			//   this.$refs.MusicPlay.play();
			// })
			// WebIM.conn.listen({
			// 	onCmdMessage(message) {
			// 		console.log('message', message)
			// 		console.log('message222', JSON.parse(message.action))
			// 		let resultData1 = JSON.parse(message.action)
			// 		let resultData = JSON.parse(resultData1.C)
			// 		console.log('resultData', resultData)
			// 		if (resultData.code == '1000') {
			// 			let order_id = resultData.order_id;
			// 			console.log('-----order_id------', order_id);

			// 			// let title = resultData.msg;
			// 			_this.order_id_list.push(order_id)
			// 			console.log('_this.order_id_list1',_this.order_id_list)
			// 			if (!_this.dialogVisible) {
			// 				_this.getOrderInfo(order_id)
			// 			}

			// 			// _this.$refs.MusicPlay.src = _this.$root.lingTiShiAudio;
			// 			// _this.$refs.MusicPlay.play();
			// 			// _this.$root.lingdang++;
			// 		}
			// 	},
			// 	onOnline() {
			// 		console.log('onOnline 网络已连接');
			// 	}, // 本机网络连接成功
			// 	onOffline() {
			// 		console.log('onOffline 网络已断开');
			// 	}, // 本机网络掉线
			// })
		},
		methods: {
			connectWebsocket() {

				let data = {
					"event": "login",
					"data": {
						'uid': store.state.user.user.id,
					}
				}
				let that = this;
				this.sendMsg(data, ev => {
					let postData = this.$util.json2obj(data);
					let resultData = this.$util.json2obj(ev.data);

					console.log('444344444444884444444data', resultData);
					let uid = store.state.user.user.id;
					// console.log('----000---uid', uid);
					let findStr = resultData.post_data;
					// console.log('----000---findStr', findStr);
					let code = resultData.code;
					console.log('----000-1111--code', code);

					if (that.$util.isHasStr(uid, findStr)) {

					  console.log('4443444444444444444code', code);
						if (code == '1100') {
							this.$refs.MusicPlay.src = this.$root.lingdangAudio;
							this.$refs.MusicPlay.play();
							this.$root.lingdang++;
						}
						//模糊电召
						if (code == '1000') {
							let order_id = resultData.order_id;
							console.log('-----order_id------', order_id);
							let title = resultData.msg;
							this.order_id_list.push(order_id)
							console.log('_this.order_id_list2',this.order_id_list)
							if (!this.dialogVisible) {
								this.getOrderInfo(order_id)
							}


						}
            
            
						if (code == '2000') {
						  this.$refs.MusicPlay.src = this.$root.InterveneAudio;
						  this.$refs.MusicPlay.play();
						  this.$root.lingdang++;
						}
            
            
						if (code == '2100') {
							this.$refs.MusicPlay.src = this.$root.InterveneAudios;
              console.log('this.$refs.MusicPlay.src',this.$refs.MusicPlay.src)
                
							this.$refs.MusicPlay.play();
							this.$root.lingdang++;
						}

						if (code == '3000') {
							this.$refs.MusicPlay.src = this.$root.subscribe;
							this.$refs.MusicPlay.play();
							this.$root.lingdang++;
						}
            
            if (code == '3100') {
            	this.$refs.MusicPlay.src = this.$root.orderyc;
            	this.$refs.MusicPlay.play();
            	this.$root.lingdang++;
            }




					}

					if (code == '1500') {
						if (store.state.user.token == 'Bearer ' + resultData.msg) {
							this.$message.error('相同账号登录，您已被迫下线！');
							this.$http.get('/login/logout').then((res) => {
								this.$store.dispatch('user/setToken').then(() => {
									window.location.replace('/');
								});
							});
						}
					}

					if (code == '1600') {
						console.log('yyyyyyyyyyyyyyyyyyy', resultData)
						if (store.state.user.token == 'Bearer ' + resultData.msg) {
							this.$http.get('/login/logout').then((res) => {
								this.$store.dispatch('user/setToken').then(() => {
									this.$router.replace({
										path: '/login',
										query: {
											code: '1600'
										}
									})
								});
								// window.location.replace('/#/login?code=1600');
							});
						}
					}




				})
			},
			saveZdOreder() {
				this.$refs['editForm'].validate((valid) => {
					//console.log(this.editForm)

					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						//如果没有选择出发地或者选择出发地后又删除了
						if (!this.editForm.paramsStr2 || !this.editForm.name2) {
							this.$message({
								type: 'error',
								message: '出发地不能为空',
							});
							this.$refs.table.reload();
							// this.dialogFormVisibleAddReturnActivity = false
							loading.close();
							return
						}



						// 重新拼接详细地址,如果地址不包含建筑物名称则拼接name
						let tempArr = this.editForm.paramsStr2.split("##");
						if (tempArr.length == 2) {
							let name = this.editForm.name2
							if (tempArr[1].indexOf(tempArr[0]) == -1) {
								let newAddress = name + "##" + tempArr[1] + tempArr[0];
								this.editForm.name2 = newAddress;
							} else {
								this.editForm.name2 = this.editForm.paramsStr2;
							}
						}
						//只输入目的地不选择或者选择了目的地然后删除了,清空name
						if (this.editForm.paramsStr && this.editForm.name) {
							let tempArr = this.editForm.paramsStr.split("##");
							let name = this.editForm.name
							if (tempArr.length == 2) {
								if (tempArr[1].indexOf(tempArr[0]) == -1) {
									let newAddress = name + "##" + tempArr[1] + tempArr[0];
									this.editForm.name = newAddress;
								} else {
									this.editForm.name = this.editForm.paramsStr;
								}
							}
						} else {
							this.editForm.name = ''
							this.editForm.paramsStr = ''
						}
						let zdFrom = this.editForm
						zdFrom.driver_id = this.carDet.id
						zdFrom.type = 1
						this.$http.post('/order/insertOrderUserToo', zdFrom).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg,
									// duration:0,
									// showClose:true
								});
								
								this.dialogVisible = false
								this.zdOrederShow = false
								this.carDet = {}
                this.refreshPage()
							} else {
								this.$message.error({
									message: res.data.msg,
									// duration:0,
									// showClose:true
								});
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
      refreshPage() {
         console.log('xiezhuxiezhuxiezhuxiezhuxiezhuxiezhuxiezhu')
         MiddleUtil.$emit('reload_xiezhu');
      },
			orderSave() {

				let postData = {};
				if (!this.editForm.place_address_title || !this.editForm.name2) {
					this.$message({
						type: 'error',
						message: '请选择订单所属地区的出发地',
					});
					return
				}
				let name = this.editForm.name2
				postData.place_address = this.editForm.place_address;
				postData.place_address_title = name


				postData.target_lng = this.editForm.lng;
				postData.target_lat = this.editForm.lat;
				postData.target_address = this.editForm.target_address;
				postData.target_address_title = this.editForm.target_address_title

				postData.audio = this.editForm.audio;
				postData.jiequ_audio = this.editForm.jiequ_audio;
				if (this.aid) {
					postData.aid = this.aid;
				} else {
					postData.aid = this.editForm.aid;
				}
				if (this.editForm.order_id) {
					postData.lng = this.editForm.lng2;
					postData.lat = this.editForm.lat2;
					postData.order_id = this.editForm.order_id;
					const loading = this.$loading({
						lock: true
					});
					console.log('-----postData', postData);
					this.$http.post('/WorkOrder/rePushCarOrder', postData).then(res => {

						console.log('admin----return--data', res);
						if (res.data.code == 0) {
							loading.close()
							this.$message({
								type: 'success',
								message: '订单推送成功'
							});
							this.aid = ''
							// this.dialogVisible = false
							// this.zdOrederShow = false
							// this.carDet = {}
              //this.$refs.table.reload();
              
              this.closeOrder()
              this.refreshPage()
						} else {
							loading.close()
							this.$message({
								type: 'error',
								message: res.data.msg
							});
							// this.closeOrder()
						}


					})
				}
			},

			handleDis() {
				this.changeArea = true
			},
			/***选择省**/
			handleChangeProv(e) {
				console.log('------------省--', this.editForm)
				var pid = ''
				this.provArr.forEach(function(item) {
					console.log('-------loop-----省--', item)
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					var data = JSON.parse(res.data)
					console.log('-----citys', data);
					this.cityArr = data
					// console.log(this.cityArr)
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/***选择市**/
			handleChangeCity(e) {
				console.log('-----------select--city', e);
				if (e) {
					/** 获取被选市的cid**/
					var cid = ''
					this.cityArr.forEach(function(item) {
						if (item.cid == e) {
							cid = item.cid
						}
					})
					/** 根据被选市的cid获取市下面的县**/
					this.$http.post('/common/area_list', {
						cid: cid
					}).then(res => {
						var adata = JSON.parse(res.data)
						console.log('----areas', adata);

						this.districtArr = adata
						/** 选择省份清空县**/
						this.table.where.aid = ''
					})
				} else {
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			closeOrder() {
				this.editForm = {
					phone: '',
					name: '',
					name2: '',
					order_id: '',
					pname: '',
					pid: '',
					cname: '',
					cid: '',
					aname: '',
					aid: '',
					lng: null,
					lng2: null,
					lat: null,
					lat2: null,
					jiequ_audio: '',
					audio: '',
					title: '有新的模糊电召订单,请及时回访协助下单!'
				}
				this.zdOrederShow = false
				this.carDet = {}
				this.cityArr = []
				this.districtArr = []
				this.dialogVisible = false;
				if(this.order_id_list.length) {
					this.order_id_list.shift()
					if(this.order_id_list.length) {
						this.getOrderInfo(this.order_id_list[0])
					}
					console.log('this.order_id_list',this.order_id_list)
				}
			},
			getOrder() {//模拟
				let order_id = '';
				setTimeout(() => {
					order_id = ''
					order_id = 'DZ0402023071011191157161196'
					this.order_id_list.push(order_id)
					if(!this.dialogVisible) {
						this.getOrderInfo(order_id)
					}
				},1000)
				setTimeout(() => {
					order_id = ''
					order_id = 'DZ0402023091518133521834972'
					this.order_id_list.push(order_id)
					// if(!this.dialogVisible) {
					// 	this.getOrderInfo(order_id)
					// }
				},3000)
				setTimeout(() => {
					order_id = ''
					order_id = 'DZ0402023091311320118198136'
					this.order_id_list.push(order_id)
					// if(!this.dialogVisible) {
					// 	this.getOrderInfo(order_id)
					// }
				},5000)
			},
			getOrderInfo(order_id,is_play_audio=1) {
				if (order_id) {
					this.$http.post('/order/detail', {
						order_id: order_id
					}).then(res => {
						console.log('@@@@1111111111111111111', res.data.data);
						let order = res.data.data;
            
            if(is_play_audio==1){
              if (order.channel == 4) {
              	this.$set(this.editForm, "title", '有新的模糊电召订单,请及时回访协助下单!')
              	this.$refs.MusicPlay.src = this.$root.lingTiShiAudio;
              	this.$refs.MusicPlay.play();
              	this.$root.lingdang++;
              } else {
              	this.$set(this.editForm, "title", '异常电召(未点链接)订单,请及时回访协助下单!')
              	this.$refs.MusicPlay.src = this.$root.InterveneAudio;
              	this.$refs.MusicPlay.play();
              	this.$root.lingdang++;
              }
            }
            
						
            
            
						this.$set(this.editForm, "phone", order.place_phone)
						this.$set(this.editForm, "name", '')
						this.$set(this.editForm, "name2", '')
						this.$set(this.editForm, "order_id", order.order_id)
						this.$set(this.editForm, "is_landline_phone", order.is_landline_phone)
						this.$set(this.editForm, "audio", order.audio)
						this.$set(this.editForm, "jiequ_audio", order.jiequ_audio)

						this.$set(this.editForm, "place_address_title", '')
						this.$set(this.editForm, "target_address_title", order.target_address_title)
						this.$set(this.editForm, "lng2", order.place_lng)
						this.$set(this.editForm, "lat2", order.place_lat)

						this.$set(this.editForm, "pname", order.province)
						this.$set(this.editForm, "pid", order.pid)
						this.$set(this.editForm, "cname", order.city)
						this.$set(this.editForm, "cid", order.cid)
						this.$set(this.editForm, "aname", order.area)
						this.$set(this.editForm, "aid", order.aid)
						this.$set(this.editForm, "city_localtion", order.city_localtion)
						this.startLng = order.place_lng;
						this.startLat = order.place_lat;
						this.$http.post('/common/city_list', {
							pid: order.pid
						}).then(res => {
							var data = JSON.parse(res.data)
							console.log('-----citys', data);
							this.cityArr = data
							// console.log(this.cityArr)
							/** 选择省份清空市县**/
							this.table.where.cid = ''
							this.table.where.aid = ''
						})
						this.$http.post('/common/area_list', {
							cid: order.cid
						}).then(res => {
							var adata = JSON.parse(res.data)
							console.log('----areas', adata);

							this.districtArr = adata
							/** 选择省份清空县**/
							this.table.where.aid = ''
							this.dialogVisible = true;
							this.$nextTick(() => {
								this.initOrderMap2()
								// this.initMap()
								// this.initMap2()
							})
						})

					})

				}


			},
			initOrderMap2() {
				let that = this
				//console.log('触发了')
				var map = new AMap.Map("orderMap2", {
					resizeEnable: true,
					zoom: 14,
					// center:[120.382665,36.066888]
					center: that.editForm.city_localtion.split(','),
					isHotspot: true
				}); //初始化地图
				that.orderMap2 = map
				AMap.plugin([
					'AMap.ToolBar',
				], function() {
					// 在图面添加工具条控件, 工具条控件只有缩放功能
					that.orderMap2.addControl(new AMap.ToolBar());

				});
				//信息窗口实例
				var infoWindow = new AMap.InfoWindow({
					offset: new AMap.Pixel(-3, 0),
					// anchor:'bottom-center'
				});
				let timer = null
				that.orderMap2.on('click', (e) => {
					setTimeout(() => {
						let nowTimer = new Date().getTime()
						if ((nowTimer - timer) <= 300) {
							return
						}
						fetch(
								`https://restapi.amap.com/v3/geocode/regeo
								?location=${e.lnglat.lng + ',' + e.lnglat.lat}
								&key=30230f9e98d7eb686572b48e9f22ba01
								&extensions=all`, {
									method: 'get'
								}).then(response => response.json())
							.then(res => {
								console.log('resres', res)
								if (res.infocode == "10000") {
									let {
										province,
										city,
										district,
										township,
										streetNumber,
										adcode
									} = res.regeocode.addressComponent
									//省市区街道拼接
									let baseAddress = province + city + district + township
									//删除省市区街道字符,获取地点名称
									let useAddress = res.regeocode.formatted_address.split(baseAddress)[1]
									//获取道路名称,用于拼接##后面的
									let street = streetNumber.street + streetNumber.number
									let obj = {
										paramsStr: useAddress ? useAddress + '##' + street : res.regeocode
											.formatted_address,
										location: e.lnglat.lng + ',' + e.lnglat.lat,
										name: useAddress ? useAddress : res.regeocode.formatted_address,
										label: useAddress ? useAddress : res.regeocode.formatted_address,
										address: street ? street : res.regeocode.formatted_address,
										adcode: adcode
									}
									that.infoWindowObj = obj
									console.log('objobjobj', that.infoWindowObj)
									let content = `<div class="InfoWindow-content2">
																		<div class="InfoWindow-title">${useAddress?useAddress:res.regeocode.formatted_address}</div>
																		<div class="InfoWindow-btn">
																			<div class="InfoWindow-btn-cont">
																				<span class="yuan1"></span>
																				<span class="btn" onclick="setAddress2('1');" style="border: 1px solid red;" >设为出发地</span>
																			</div>
																			<div class="InfoWindow-btn-cont">
																				<span class="yuan2"></span>
																				<span class="btn" onclick="setAddress2('2')" style="border: 1px solid #33CC99;">设为目的地</span>
																			</div>												
																		</div>
																		<div class="info-shu">
																		</div>
																</div>`
									infoWindow.setContent(content)
									infoWindow.open(that.orderMap2, e.lnglat);
								}
							})
					}, 10)
				})
				//点击地图上展示出来的地点
				that.orderMap2.on('hotspotclick', (e) => {
					console.log('eee', e)
					timer = new Date().getTime() //获取时间戳
					if (e.id) {
						fetch(
								`https://restapi.amap.com/v3/place/detail
								?id=${e.id}
								&key=30230f9e98d7eb686572b48e9f22ba01`, {
									method: 'get'
								}).then(response => response.json())
							.then(res => {
								console.log('resresres', res)
								if (res.infocode == "10000") {
									if (res.pois[0]) {
										let obj = {
											paramsStr: res.pois[0].name + '##' + res.pois[0].address,
											location: res.pois[0].location,
											name: res.pois[0].name,
											label: res.pois[0].name,
											address: res.pois[0].address,
											adcode: res.pois[0].adcode
										}
										that.infoWindowObj = obj
										let content = `<div class="InfoWindow-content2">
																			<div class="InfoWindow-title">${res.pois[0].name}</div>
																			<div class="InfoWindow-btn">
																				<div class="InfoWindow-btn-cont">
																					<span class="yuan1"></span>
																					<span class="btn" onclick="setAddress2('1');" style="border: 1px solid red;" >设为出发地</span>
																				</div>
																				<div class="InfoWindow-btn-cont">
																					<span class="yuan2"></span>
																					<span class="btn" onclick="setAddress2('2')" style="border: 1px solid #33CC99;">设为目的地</span>
																				</div>												
																			</div>
																			<div class="info-shu">
																			</div>
																	</div>`
										infoWindow.setContent(content)
										infoWindow.open(that.orderMap2, e.lnglat);
									} else {

										fetch(
												`https://restapi.amap.com/v3/geocode/regeo
												?location=${e.lnglat.lng + ',' + e.lnglat.lat}
												&key=30230f9e98d7eb686572b48e9f22ba01
												&extensions=all`, {
													method: 'get'
												}).then(response => response.json())
											.then(res => {
												console.log('resres', res)
												if (res.infocode == "10000") {
													let {
														province,
														city,
														district,
														township,
														streetNumber,
														adcode
													} = res.regeocode.addressComponent
													//省市区街道拼接
													let baseAddress = province + city + district + township
													//删除省市区街道字符,获取地点名称
													let useAddress = res.regeocode.formatted_address.split(baseAddress)[1]
													//获取道路名称,用于拼接##后面的
													let street = streetNumber.street + streetNumber.number
													// 窗体标题
													/**
													 * 此具体地址是截取后不为空的地址 useAddress
													 * 
													 * 
													 * 接口返回有具体地址?接口返回具体地址包含点击的地点名并且具体地址不包含'号楼、栋、幢'几个字:展示点击的地点名
													 * 否则具体地址包含'号楼、栋、幢'几个字:展示接口返回的具体地点名:否则展示接口返回的具体地点名+点击的地点名:
													 * 否则截取完的具体地址为空.直接展示未截取的地址
													 */
													let title = useAddress ? useAddress.indexOf(e.name) != -1 && useAddress.indexOf(
															'号楼') == -1 && useAddress.indexOf('幢') == -1 && useAddress.indexOf('栋') == -1 ?
														e.name : useAddress.indexOf('号楼') != -1 || useAddress.indexOf('幢') != -1 ||
														useAddress.indexOf('栋') != -1 ? useAddress :
														useAddress + e.name : res.regeocode.formatted_address
													let obj = {
														paramsStr: title + '##' + street,
														location: e.lnglat.lng + ',' + e.lnglat.lat,
														name: title,
														label: title,
														address: street ? street : res.regeocode.formatted_address,
														adcode: adcode
													}
													that.infoWindowObj = obj
													let content = `<div class="InfoWindow-content2">
																						<div class="InfoWindow-title">${useAddress?useAddress+e.name:res.regeocode.formatted_address}</div>
																						<div class="InfoWindow-btn">
																							<div class="InfoWindow-btn-cont">
																								<span class="yuan1"></span>
																								<span class="btn" onclick="setAddress2('1');" style="border: 1px solid red;" >设为出发地</span>
																							</div>
																							<div class="InfoWindow-btn-cont">
																								<span class="yuan2"></span>
																								<span class="btn" onclick="setAddress2('2')" style="border: 1px solid #33CC99;">设为目的地</span>
																							</div>												
																						</div>
																						<div class="info-shu">
																						</div>
																				</div>`
													infoWindow.setContent(content)
													infoWindow.open(that.orderMap2, e.lnglat);
												}
											})
									}

								}
							})
					} else {
						that.$message('抱歉!未能获取该地点信息')
					}
				})
			},
			refreshCars2() {
				if (this.editForm.paramsStr2 && this.editForm.name2) {
					this.zdOrederShow = false
					this.iconLoading = true
					let obj = {
						lng: this.editForm.lng2,
						lat: this.editForm.lat2,
					}
					this.getCars(obj)
				} else {
					this.$message('请先选择出发地')
				}

			},
			//获取附近车辆
			getCars(obj) {
				let that = this
				if (this.markerCars.length) {
					this.markerCars.forEach(item => {
						this.orderMap2.remove(item)
					})
				}
				this.$http.post('/order/getNearbyDrivers', {
					lng: obj.lng,
					lat: obj.lat,
					max_distance: 2,
				}).then(res => {
					var marker, icon
					if (res.data.code == 100) {
						if (res.data.data.length) {
							res.data.data.forEach(item => {
								item.latlngArr = [item.lng, item.lat]
								item.distance = this.getMapDistance(obj.lng, obj.lat, item.lng, item.lat)
								icon = new AMap.Icon({
									size: new AMap.Size(50, 70),
									image: item.car_image,
									imageSize: new AMap.Size(50, 70),
									// imageOffset: new AMap.Pixel(-95, -3)
								});
								marker = new AMap.Marker({
									position: item.latlngArr, //不同标记点的经纬度
									map: that.orderMap2,
									icon: icon,
								});
								that.markerCars.push(marker)
								marker.on('click', (e) => {
									return this.markerClick(item)
								});
							})
						} else {
							that.$message({
								message: '附近暂无车辆',
								customClass: 'cars-message',
							})
						}
						that.iconLoading = false
					} else {
						that.iconLoading = false
						that.$message({
							type: 'error',
							message: res.data.msg
						})
					}
				}).catch(err => {
					that.iconLoading = false
					that.$message({
						type: 'error',
						message: err.message
					})
				})
			},
			markerClick(item) {
				console.log('item', item)
				this.carDet = item
				this.zdOrederShow = true
			},
			zdClose() {
				this.carDet = {}
				this.zdOrederShow = false
			},
			getMapDistance(lng1, lat1, lng2, lat2) {
				var d1 = 0.01745329251994329;
				var d2 = lng1;
				var d3 = lat1;
				var d4 = lng2;
				var d5 = lat2;
				d2 *= d1;
				d3 *= d1;
				d4 *= d1;
				d5 *= d1;
				var d6 = Math.sin(d2);
				var d7 = Math.sin(d3);
				var d8 = Math.cos(d2);
				var d9 = Math.cos(d3);
				var d10 = Math.sin(d4);
				var d11 = Math.sin(d5);
				var d12 = Math.cos(d4);
				var d13 = Math.cos(d5);
				var arrayOfDouble1 = [];
				var arrayOfDouble2 = [];
				arrayOfDouble1.push(d9 * d8);
				arrayOfDouble1.push(d9 * d6);
				arrayOfDouble1.push(d7);
				arrayOfDouble2.push(d13 * d12);
				arrayOfDouble2.push(d13 * d10);
				arrayOfDouble2.push(d11);
				var d14 = Math.sqrt((arrayOfDouble1[0] - arrayOfDouble2[0]) * (arrayOfDouble1[0] - arrayOfDouble2[0]) +
					(arrayOfDouble1[1] - arrayOfDouble2[1]) * (arrayOfDouble1[1] - arrayOfDouble2[1]) +
					(arrayOfDouble1[2] - arrayOfDouble2[2]) * (arrayOfDouble1[2] - arrayOfDouble2[2]));
				var distance = (Math.asin(d14 / 2.0) * 12742001.579854401) / 1000;
				return distance.toFixed(1);
			},

			searchTitleHtml(val) {
				if (this.searchValue.length == 0) {
					return val;
				}

				if (val.indexOf(this.searchValue) != -1) {
					let span = "<span style=\"color:red;\">" + this.searchValue + "</span>"
					return val.replace(this.searchValue, span);
				}
				return val;

			},
			schange(item) {
				console.log('item', item)
				let that = this
				if (that.marker) {
					that.orderMap2.remove(that.marker)
				}
				//筛选出选中的对象
				let latlngArr = item.location.split(',')
				this.editForm.name = item.name
				this.editForm.paramsStr = item.paramsStr
				this.editForm.target_address_title = item.label
				this.editForm.target_address = item.address
				this.editForm.lng = latlngArr[0]
				this.editForm.lat = latlngArr[1]
				// that.orderMap.clearMap()
				let icon = new AMap.Icon({
					// 图标尺寸
					size: new AMap.Size(25, 34),
					image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
					imageSize: new AMap.Size(135, 40),
					// 图标取图偏移量
					imageOffset: new AMap.Pixel(-95, -3)
				});
				this.marker = new AMap.Marker({
					position: latlngArr,
					icon: icon,
				});
				that.orderMap2.add(this.marker);
				that.orderMap2.setFitView();
			},
			querySearchAsync(query, cb) {
				this.searchValue = query;
				console.log('query', query)
				let cid = this.editForm.cid ? this.editForm.cid : this.adminCid
				let aid = this.editForm.aid
				if (query) {
					fetch(
							`https://restapi.amap.com/v3/place/text?keywords=${query}&city=${cid ? cid : ''}&citylimit=${cid?'false':'true'}&offset=10&page=1&key=30230f9e98d7eb686572b48e9f22ba01`, {
								method: 'get'
							}).then(response => response.json())
						.then(res => {
							console.log('res', res)
							if (res.infocode == 10000) {
								this.sOptions = res.pois
								this.sOptions.forEach(item => {
									item.paramsStr = item.name + "##" + item.address
									item.value = item.name
									item.label = item.value
								})
								var restaurants = this.sOptions;
								if (restaurants.length == 0) {
									this.querySearchAsync1(query, cb)
								} else {
									cb(restaurants);
								
								}
							} else {
								this.$message({
									message: res.info
								});
							}
						})
				}
			},
			querySearchAsync1(query, cb) {//高德输入提示查询API
				this.searchValue = query;
				let cid = this.editForm.cid ? this.editForm.cid : this.adminCid
				let aid = this.editForm.aid
				if (query) {
					fetch(
							`https://restapi.amap.com/v3/assistant/inputtips?keywords=${query}
							&city=${cid ? cid : ''}&citylimit=${cid?'false':'true'}
							&datatype=all&key=30230f9e98d7eb686572b48e9f22ba01`, {
								method: 'get'
							}).then(response => response.json())
							.then(res => {
								console.log('querySearchAsync1res', res)
								if (res.infocode == 10000) {
									this.sOptions = res.tips
									this.sOptions.forEach(item => {
										item.paramsStr = item.name + '##' + item.address
										item.value = item.name
										item.label = item.value
									})
									var restaurants = this.sOptions;
									
									cb(restaurants);
								} else {
									this.$message({
										message: res.info
									});
								}
							})
				}
			},
			//获取adcode权限
			getAdcodes() {
				this.$http.post('/order/getAdminCity')
					.then(res => {
						console.log(res)
						this.adcodes = res.data.data.area_id

						let oCid = res.data.data.city_id;
						let oPid = res.data.data.province_id;
						if (oCid) {
							if (oCid.indexOf(",") == -1) {
								this.adminCid = oCid;
							} else {
								if (oPid) {
									if (oPid.indexOf(",") == -1) { // 单个省
										this.adminCid = oPid
									} else { // 多个省
										console.log("省列表", oPid);
									}
								}
							}
						}
					})
			},
			schange2(item) {
				console.log('item', item)
				if (this.adcodes && this.adcodes.indexOf(item.adcode) == -1) {
					this.editForm.name2 = ''
					this.$message({
						message: '请选择订单所属地区的出发地'
					})
					return
				}
				let that = this
				if (that.marker2) {
					that.orderMap2.remove(that.marker2)
				}
				//筛选出选中的对象
				let latlngArr = item.location.split(',')
				this.editForm.name2 = item.name
				this.editForm.paramsStr2 = item.paramsStr
				this.editForm.place_address_title = item.label
				this.editForm.place_address = item.address
				this.aid = item.adcode
				this.editForm.lng2 = latlngArr[0]
				this.editForm.lat2 = latlngArr[1]
				// that.orderMap.clearMap()
				let icon = new AMap.Icon({
					// 图标尺寸
					size: new AMap.Size(25, 34),
					image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
					imageSize: new AMap.Size(135, 40),
					// 图标取图偏移量
					imageOffset: new AMap.Pixel(-9, -3)
				});
				this.marker2 = new AMap.Marker({
					position: latlngArr,
					icon: icon,
				});
				that.orderMap2.add(this.marker2);
				//用来获取附近车辆
				let obj = {
					lng: latlngArr[0],
					lat: latlngArr[1],
				}
				that.getCars(obj)
				that.orderMap2.setFitView();
				console.log('this.12312', this.editForm)
			},
			querySearchAsync4(query, cb) {//高德输入提示查询API
				this.searchValue = query;
				let cid = this.editForm.cid ? this.editForm.cid : this.adminCid
				let aid = this.editForm.aid
				if (query) {
					fetch(
							`https://restapi.amap.com/v3/assistant/inputtips?keywords=${query}
							&city=${aid ? aid : cid ? cid : ''}&citylimit=${cid ? 'true' : 'false'}
							&datatype=all&key=30230f9e98d7eb686572b48e9f22ba01`, {
								method: 'get'
							}).then(response => response.json())
							.then(res => {
								if (res.infocode == 10000) {
									this.sOptions2 = res.tips
									this.sOptions2.forEach(item => {
										item.paramsStr = item.name + '##' + item.address
										item.value = item.name
										item.label = item.value
									})
									var restaurants = this.sOptions2;
									// if (restaurants.length == 0) {
									// } else {
									// 	cb(restaurants);
									
									// }
									cb(restaurants);
								} else {
									this.$message({
										message: res.info
									});
								}
							})
				}
			},
			querySearchAsync2(query, cb) {
				console.log('query', query)
				this.searchValue = query;
				let cid = this.editForm.cid ? this.editForm.cid : this.adminCid
				let aid = this.editForm.aid
				if (query) {
					fetch(
							`https://restapi.amap.com/v3/place/text?keywords=${query}&extensions=all
							&city=${aid ? aid : cid ? cid : ''}
							&citylimit=${cid ? 'true' : 'false'}
							&offset=20&page=1&key=30230f9e98d7eb686572b48e9f22ba01`, {
								method: 'get'
							}).then(response => response.json())
						.then(res => {
							console.log('res', res)
							if (res.infocode == 10000) {
								this.sOptions2 = res.pois
								this.sOptions2.forEach(item => {
									item.paramsStr = item.name + "##" + item.address
									item.value = item.name
									item.label = item.value
								})
								var restaurants = this.sOptions2;
								if (restaurants.length == 0) {
									this.querySearchAsync3(query, cb);
								} else {
									cb(restaurants);

								}
							} else {
								this.$message({
									message: res.info
								});
							}
						})
				}
			},
			querySearchAsync3(query, cb) {
				console.log('query', query)
				this.searchValue = query;
				let cid = this.editForm.cid ? this.editForm.cid : this.adminCid
				let aid = this.editForm.aid
				if (query) {
					fetch(
							`https://restapi.amap.com/v3/place/text?keywords=${query}
							&extensions=all&city=${aid ? aid : cid ? cid : ''}
							&citylimit=true
							&offset=20&page=1&key=30230f9e98d7eb686572b48e9f22ba01`, {
								method: 'get'
							}).then(response => response.json())
						.then(res => {
							console.log('res', res)
							if (res.infocode == 10000) {
								this.sOptions2 = res.pois
								this.sOptions2.forEach(item => {
									item.paramsStr = item.name + "##" + item.address
									item.value = item.name
									item.label = item.value
								})
								var restaurants = this.sOptions2;
								if (restaurants.length == 0) {
									this.querySearchAsync4(query, cb);
								} else {
									cb(restaurants);
								
								}
							} else {
								this.$message({
									message: res.info
								});
							}
						})
				}
			},

			/* 获取当前用户信息 */
			getUserInfo() {
				if (this.$setting.userUrl) {
					this.$http.get(this.$setting.userUrl).then(res => {
						if (res.data.code === 0) {
							if (this.$setting.parseUser) {
								res.data = this.$setting.parseUser(res.data);
							}
							let user = res.data.data;
							this.$store.dispatch('user/setUser', user);
							this.$store.dispatch('user/setRoles', user ? user.roles : null);
							this.$store.dispatch('user/setAuthorities', user ? user.authorities : null);
							// 设置节点权限
							this.$store.dispatch('user/setPermission', user ? user.permissionList : null);
							let options = {
								// user: JSON.parse(localStorage.getItem('user')).hx_username,
								user: JSON.parse(localStorage.getItem('user')).uuid,
								pwd: 'yly2003admin',
								appKey: WebIM.config.appkey,
								apiUrl: 'https://a1.easecdn.com'
							};
							WebIM.conn.open(options);
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						this.$message.error(e.message);
					});
				}
			},
			/* 折叠/展开侧边栏 */
			collapse() {
				this.$store.dispatch('theme/toggle', 'collapse');
			},
			/* 窗口大小改变监听 */
			onWindowResize() {
				let screenWidth = document.documentElement.clientWidth || document.body.clientWidth;
				if (this.$store.state.theme.screenWidth !== screenWidth) {
					this.$store.dispatch('theme/set', {
						key: 'screenWidth',
						value: screenWidth
					});
				}
			}
		},

		destroyed() {
			// 销毁屏幕resize监听
			if (this.$setting.watchResize) {
				window.removeEventListener('resize', this.onWindowResize);
			}
		},
		watch: {
			/* 监听路由变化 */
			$route() {
				if (this.theme.screenWidth < 768) {
					this.$store.dispatch('theme/set', {
						key: 'collapse',
						value: true
					});
				}
				if (!this.$setting.watchResize) {
					this.onWindowResize();
				}
			}
		}
	}
</script>

<style scoped>
	.place_point {
		width: 10px;
		height: 10px;
		margin: 13px 0 0 40px;
		border-radius: 50%;
		background-color: #F8140E;
	}

	.target_point {
		width: 10px;
		height: 10px;
		margin: 13px 0 0 40px;
		border-radius: 50%;
		background-color: #35CC99;
	}

	.order-map2 {
		width: 100%;
		height: 800px;
		position: relative;
	}

	.map-form2 {
		position: absolute;
		top: 60px;
		left: 10px;
		z-index: 100;
	}

	.zd-order2 {
		position: absolute;
		left: 10px;
		/* top: 370px; */
		z-index: 100;
		width: 540px;
	}

	.zd-order-header2 {
		display: flex;
		justify-content: space-between;
	}

	.header-tit2 {
		color: #409EFF;
		font-weight: bold;
	}

	.header-close2:hover {
		cursor: pointer;
	}

	.zd-order-cont2 {
		margin-top: 10px;
		position: relative;
	}

	.zp-btn2 {
		position: absolute;
		width: 150px;
		right: 50px;
		top: 60px;
	}

	.zd-order-cont2 div {
		margin-bottom: 5px;
	}

	.zd-order-cont2 div span {
		margin-right: 10px;
	}

	.city-select2 {
		width: 120px;
		margin-right: 10px;
	}

	::v-deep .el-dialog__body {
		padding: 0 0 0 0 !important;
		max-height: 800px !important;
	}

	.order-input2 {
		width: 350px;
	}

	.my-autocomplete2 li {
		line-height: normal;
		padding: 7px 7px 15px 7px;
	}

	.my-autocomplete2 li .name {
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: normal;
	}

	.my-autocomplete2 li .addr {
		font-size: 12px;
		color: #b4b4b4;
		line-height: normal;
		margin-top: 3px;
		margin-bottom: 8px;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.my-autocomplete2 li .highlighted .addr {
		color: #ddd;
		margin-top: 3px;
		margin-bottom: 8px;
		line-height: normal;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	::v-deep .el-dialog {
		/* margin-top: 60px !important; */
		max-width: 1400px;
	}

	::v-deep .el-dialog .el-dialog__body {
		padding: 0 0 0 0 !important;
		max-height: 800px !important;
	}

	.refresh-cars2:hover {
		cursor: pointer;
	}

	/* 人工下单窗体样式 */
	/deep/.InfoWindow-content2 {
		width: 300px;
		border-radius: 10px;
		overflow: hidden;
	}

	/deep/.InfoWindow-title {
		width: 100%;
		box-sizing: border-box;
		background-color: #33CC99;
		padding: 15px 10px;
		/* border-top-left-radius:10px ;
		border-top-right-radius:10px ; */
		font-size: 16px;
		color: white;
		font-weight: bold;
		text-align: center;
		/* box-shadow: 0 0 10px rgba(0,0,0,.3); */
	}

	::v-deep .InfoWindow-btn {
		background-color: white;
		width: 100%;
		box-sizing: border-box;
		padding: 15px;
		display: flex;
		justify-content: space-around;
		position: relative;
		box-shadow: 0 3px 10px rgba(0, 0, 0, .3);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	::v-deep .info-shu {
		/* position: absolute; */
		width: 5px;
		height: 20px;
		background-color: #33CC99;
		margin-left: 50%;
		/* 	bottom: -20px;
		left: 50%; */
	}

	::v-deep .InfoWindow-btn-cont {
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	::v-deep .InfoWindow-btn-cont .btn {
		display: flex;
		height: 25px;
		line-height: 25px;
		padding: 0px 5px;
		border-radius: 5px;
		/* align-items: center; */
		margin-left: 5px;
	}

	::v-deep .yuan1 {
		display: inline-block;
		width: 10px;
		height: 10px;
		background-color: red;
		border-radius: 50%;
	}

	::v-deep .yuan2 {
		display: inline-block;
		width: 10px;
		height: 10px;
		background-color: #33CC99;
		border-radius: 50%;
	}

	::v-deep .amap-info-content {
		background-color: transparent;
		padding: 0 !important;
	}

	::v-deep .amap-info-close {
		right: 5px !important;
		top: 5px !important;
		color: white !important;
	}

	::v-deep .amap-info-sharp {
		display: none !important;
	}

	::v-deep .amap-info-outer,
	.amap-menu-outer {
		box-shadow: none !important;
	}
</style>