/**
 * axios配置
 */
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store'
import router from '@/router'
import setting from './setting'
import {MessageBox} from 'element-ui'

Vue.use(VueAxios, axios);
//600秒
axios.defaults.timeout = 1000*60*600
axios.defaults.baseURL = setting.baseURL;
axios.defaults.headers.common[setting.tokenHeaderName] = store.state.user.token;
//请求拦截
axios.interceptors.request.use(config => {
	//发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
	// config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
	// config.headers['rip'] = localStorage.getItem('ip') && localStorage.getItem('ip')!= 'undefined'?localStorage.getItem('ip'):''
	//如有需要：注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
	//const token = getCookie('名称');//这里取token之前，你肯定需要先拿到token,存一下
	//    if(token){
	//       config.params = {'token':token} //如果要求携带在参数中
	//       config.headers.token= token; //如果要求携带在请求头中
	//     }
	return config
}, error => {
	Promise.reject(error)
})
/* 响应拦截器 */
axios.interceptors.response.use((res) => {
    if (res.data.code === 401) {  // 登录过期处理
        if (res.config.url === setting.menuUrl) {
            store.dispatch('user/setToken').then(() => {
                location.replace('/');
            });
            return;
        }
        MessageBox.alert('登录状态已过期，请退出重新登录！', '系统提示', {
            confirmButtonText: '重新登录',
            callback: action => {
                if (action === 'confirm') {
                    store.dispatch('user/setToken').then(() => {
                        router.push({path: '/login'});
                    });
                }
            },
            beforeClose: () => {
                MessageBox.close();
            }
        });
        return Promise.reject(new Error(res.data.msg));
    }
    // token自动续期
    let access_token = res.headers[setting.tokenHeaderName];
    if (access_token) store.dispatch('user/setToken', access_token);
    return res;
}, (error) => {
    return Promise.reject(error);
});
